import { graphql } from "gatsby"
import GatsbyImage from "gatsby-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import React from "react"
import ButtonLink from "../components/buttonLink"
import RichTextRenderer from "../components/RichTextRenderer"

const DivisionPageTemplate = ({
  data: {
    contentfulDivision: { divisionName, divisionLogo, pageContent },
  },
}) => {
  return (
    <div className=" mt-10 justify-self-center max-w-6xl grid justify-start px-2">
      {divisionLogo?.fixed ? (
        <div className="grid justify-center">
          <GatsbyImage fixed={divisionLogo?.fixed} />
        </div>
      ) : null}
      <h1>{divisionName}</h1>
      {pageContent && renderRichText(pageContent)}

      <ButtonLink
        buttonText="All Divisions"
        linkTo="/divisions"
        className="my-10 self-end"
      />
    </div>
  )
}

export const query = graphql`
  query DivisionsTemplateQuery($id: String!) {
    contentfulDivision(id: { eq: $id }) {
      id
      divisionName
      divisionLogo {
        fixed(width: 250) {
          ...GatsbyContentfulFixed_withWebp
        }
        title
      }
      pageContent {
        raw
      }
    }
  }
`

export default DivisionPageTemplate
