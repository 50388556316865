import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import React from "react"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const RichTextRenderer = ({ content }) => {
  return <div>{renderRichText(content, options)}</div>
}

// https://github.com/contentful/rich-text/blob/master/packages/rich-text-types/src/blocks.ts
// https://github.com/contentful/rich-text/blob/master/packages/rich-text-types/src/inlines.ts
// https://github.com/contentful/rich-text/blob/master/packages/rich-text-types/src/marks.ts

const Bold = ({ children }) => <span className="font-bold">{children}</span>

const H1 = ({ children }) => <h1 className="pb-5">{children}</h1>

const EmbeddedFileDownload = ({ title = "", url = null }) => {
  return (
    <div>
      <a href={url} className="underline" download>
        {title}
      </a>{" "}
    </div>
  )
}
const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const contentType = node.data.target.fields.file["en-US"].contentType
      const isImage = contentType.includes("image")
      const title = node.data.target.fields.title["en-US"]
      const url = node.data.target.fields.file["en-US"].url

      if (isImage) {
        return <img src={`https:${url}?w=1000`} alt="" />
      }

      //   const { title } = node.data.target.fields
      return <EmbeddedFileDownload title={`${title}`} url={url} />
    },
    [BLOCKS.HEADING_1]: (node, children) => <H1>{children}</H1>,
    // [BLOCKS.UL_LIST]: (node, children) => (
    //   <ul className="list-disc">{children}</ul>
    // ),
    [INLINES.HYPERLINK]: (node, children) => {
      return (
        <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      )
    },
    [INLINES.ASSET_HYPERLINK]: (node, children) => {
      return (
        <a href={node.data.target.fields.file["en-US"].url} download>
          {children}
        </a>
      )
    },
  },
}

export default RichTextRenderer
